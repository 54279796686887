import React from "react"
import Scrollchor from "react-scrollchor"

import SEO from "../components/seo"
import Footer from "../components/footer"
import PlugPlayType from "../components/plugPlayType"

import "../styles/main.scss"
import "../styles/landing.scss"

const mailText = "mailto:team@framehub.io"

const LandingPage = props => {
  return (
    <div className="container-fluid p-0 landing-page">
      <SEO title="Framehub" />
      <div className="landing-hero-section position-relative">
        <img
          className="land-img"
          src={props.data.heroImg.childImageSharp.fluid.src}
          alt="hero-img"
        />
        <div className="hero-box-gradient" />
        <div className="grey-overlay-container">
          <div className="grey-overlay-inn-container"></div>
        </div>
        <div className="grey-overlay-container1">
          <div className="grey-overlay-inn-container1"></div>
        </div>
        <div className="hero-content-container">
          <div className="hero-content-inn-container">
            <img
              className="logo"
              src={props.data.logoWithTextImg.childImageSharp.fluid.src}
              alt="logo"
            />
            <div className="lc-container">
              <div className="title">
                Nutzen sie das volle Potential das Video zu bieten hat.
              </div>
              <br />
              <div className="description">
                framehub erlaubt ihnen ihre Videoinhalte in ihrem eigenen,
                intelligenten Video-Hub zu bündeln.
              </div>
              <br />
              <br />
              <a className="white-cust-btn" href={mailText}>
                Demo anfordern
              </a>
            </div>
          </div>
        </div>
        <Scrollchor to="#banner-text-container" className="bottom-arrow-cont">
          <img
            className="arrow-img"
            src={props.data.bottomArrow.childImageSharp.fluid.src}
            alt="btn-arrow"
          />
        </Scrollchor>
      </div>

      <div id="banner-text-container" className="banner-text-container">
        <div className="banner-text-inner-container">
          <img src={props.data.logoImg.childImageSharp.fluid.src} alt="logo" />
          <p className="title">Das Video-CMS</p>
          <p className="subtitle">
            Der cleverste Weg, um ihre Videoinhalte auf ihrer Webseite zu zeigen
          </p>
        </div>
      </div>

      <div className="img-content-container container">
        <div className="row">
          <div className="col-md-6 p-0 content-container cont-order-mob">
            <div className="content-inner-container">
              <p className="subtitle">EXPERIENCE</p>
              <p className="title">Videoinhalte bündeln</p>
              <p className="description">
                Bündeln Sie mit Framehub ihre Videoinhalte auf ihrer eigenen
                Website und nutzen Sie endlich die unzähligen Vorteile eines
                eigenen Video-Hubs
              </p>
            </div>
          </div>
          <div className="col-md-6 p-0 img-container img-order-mob">
            <img
              className="cus-img float-right"
              src={props.data.homeImg.childImageSharp.fluid.src}
              alt="home"
            />
          </div>
        </div>
      </div>

      <div className="img-content-container container web-layer-container">
        <div className="row">
          <div className="col-md-6 p-0 img-container img-order-mob">
            <img
              className="cus-img float-left"
              src={props.data.wlImg.childImageSharp.fluid.src}
              alt="wl"
            />
          </div>
          <div className="col-md-6 p-0 content-container cont-order-mob position-relative">
            <div className="content-inner-container">
              <p className="subtitle">SETUP</p>
              <p className="title">Nahtlos integrieren</p>
              <p className="description">
                Integrieren Sie Framehub mühelos in ihre Webseite und überzeugen
                Sie ihre Besucher mit einem Netflix ähnlichen Erlebnis
              </p>
              <a className="white-cust-btn" href={mailText}>
                Demo anfordern
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="img-content-container container">
        <div className="row">
          <div className="col-md-5 p-0 content-container cont-order-mob">
            <div className="content-inner-container">
              <p className="subtitle">CONTENT MANAGEMENT</p>
              <p className="title">Collections & Playlists erstellen</p>
              <p className="description">
                Erstellen Sie die richtigen Playlists für die jeweiligen
                Nutzergruppen und steuern Sie endlich welche Inhalte ihre Nutzer
                konsumieren
              </p>
            </div>
          </div>
          <div className="col-md-7 p-0 img-container img-order-mob">
            <img
              className="cus-img float-right"
              src={props.data.Dashboard0Img.childImageSharp.fluid.src}
              alt="dashboard"
            />
          </div>
        </div>
      </div>

      <div className="img-content-container container nv-container">
        <div className="row">
          <div
            style={{ zIndex: 2 }}
            className="col-md-6 p-0 img-container img-order-mob"
          >
            <img
              className="cus-img float-left"
              src={props.data.Dashboard1Img.childImageSharp.fluid.src}
              alt="dashboard"
            />
          </div>
          <div className="col-md-6 p-0 content-container cont-order-mob position-relative">
            <div className="content-inner-container">
              <p className="subtitle">ANALYTICS</p>
              <p className="title">Nutzerverhalten verstehen</p>
              <p className="description">
                Über das Framehub-Dashboard lassen sich alle wichtigen
                Informationen über das Nutzerverhalten abrufen, sodass Sie Ihre
                Marketingmaßnahmen zahlenbasiert steuern können
              </p>
              <a className="white-cust-btn" href={mailText}>
                Demo anfordern
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="img-content-container container um-container">
        <div className="row">
          <div className="col-md-6 p-0 content-container cont-order-mob">
            <div className="content-inner-container">
              <p className="subtitle">USER MANAGEMENT</p>
              <p className="title">Nutzerrechte verwalten</p>
              <p className="description">
                Ob Bestanskunde, Lead oder Mitarbeiter, Framehub lässt Sie
                bestimmen, welche Inhalte für einen jeweiligen Nutzer
                freigeschaltet werden
              </p>
            </div>
          </div>
          <div
            style={{ zIndex: 2 }}
            className="col-md-6 p-0 img-container img-order-mob"
          >
            <img
              className="cus-img float-right"
              src={props.data.Dashboard2Img.childImageSharp.fluid.src}
              alt="dashboard"
            />
          </div>
        </div>
      </div>

      <div
        style={{ paddingTop: 0, paddingBottom: "25px" }}
        className="banner-text-container"
      >
        <div className="bt-cont banner-text-inner-container">
          <p className="title">
            Plug & Play mit den gängigsten <br />
            Webtechnologien
          </p>
          <p style={{ maxWidth: "520px" }} className="subtitle">
            Framehub ist über eine Subdomain in ihre Webseite integriert
          </p>
        </div>
      </div>

      <div className="row justify-content-center mb-4 pb-4">
        <div className="col-md-12 plug-play-cont">
          <PlugPlayType
            imgSrc={props.data.wordpressImg.childImageSharp.fluid.src}
            text="WordPress"
          />
          <PlugPlayType
            imgSrc={props.data.typo3Img.childImageSharp.fluid.src}
            text="Typo3"
          />
          <PlugPlayType
            imgSrc={props.data.contentfulCmsImg.childImageSharp.fluid.src}
            text="Contentful"
          />
          <PlugPlayType
            imgSrc={props.data.customIconImg.childImageSharp.fluid.src}
            text="Custom"
          />
        </div>
      </div>

      <div className="row sd-container">
        <div className="col-md-12 sd-inner-container">
          <p className="heading">Haben wir ihr Interesse Geweckt?</p>
          <div className="btns-container">
            <a className="projects-link-2 display-desktop" href={mailText}>
              Support kontaktieren
            </a>
            <a className="white-cust-btn display-desktop" href={mailText}>
              Demo anfordern
            </a>
            <a className="projects-link-2 display-mobile" href={mailText}>
              Demo anfordern
            </a>
            <a className="white-cust-btn display-mobile" href={mailText}>
              Support kontaktieren
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default LandingPage

export const query = graphql`
  {
    heroImg: file(relativePath: { eq: "landing-page/main-hero-pic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoWithTextImg: file(relativePath: { eq: "framehublogo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logoImg: file(relativePath: { eq: "favicon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Dashboard0Img: file(relativePath: { eq: "landing-page/Dashboard-0.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Dashboard1Img: file(relativePath: { eq: "landing-page/Dashboard-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Dashboard2Img: file(relativePath: { eq: "landing-page/Dashboard-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImg: file(relativePath: { eq: "landing-page/home.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    typo3Img: file(relativePath: { eq: "landing-page/typo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wordpressImg: file(relativePath: { eq: "landing-page/wordpress.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    customIconImg: file(relativePath: { eq: "landing-page/custom-icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contentfulCmsImg: file(
      relativePath: { eq: "landing-page/contentful-cms.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wlImg: file(relativePath: { eq: "landing-page/wl.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomArrow: file(relativePath: { eq: "botton-arrow.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
